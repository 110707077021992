<template>
    <div>
        <v-tooltip
            v-if="mostrarBotaoFlutuante && selecionados.length !== 0"
            top
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    fab
                    dark
                    color="green"
                    fixed
                    right
                    bottom
                    @click="dialogTemCerteza"
                >
                    <v-icon dark>check</v-icon>
                </v-btn>
            </template>
            <span>Solicitar {{type}} para Selecionados</span>
        </v-tooltip>
        <v-card class="mt-3">
            <v-row class="ma-0">
                <v-spacer></v-spacer>
                <v-col sm="6" md="3" lg="4" class="d-flex justify-end pr-9">
                    <v-tooltip top :disabled="selecionados.length !== 0">
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-btn
                                    class="d-flex justify-center"
                                    color="green white--text"
                                    @click="dialogTemCerteza"
                                    :disabled="!selecionados.length"
                                    v-intersect="onIntersect"
                                >
                                    Solicitar {{type}} para Selecionados
                                    <v-icon class="ml-2"
                                        >mdi-chevron-right</v-icon
                                    >
                                </v-btn>
                            </div>
                        </template>
                        <span
                            >Selecione um ou mais títulos para liberar o
                            comando</span
                        >
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog
            v-if="temCerteza"
            v-model="temCerteza"
            max-width="800px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-toolbar-title v-if="titulosEnviadosCheck">
                        Tem certeza?
                    </v-toolbar-title>
                    <v-toolbar-title v-if="resultadoCheck">
                        Envio Finalizado!
                    </v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text
                    v-if="titulosEnviadosCheck"
                    min-height="200px"
                    style="border-radius: 0"
                >
                    <v-list shaped>
                        <v-subheader
                            class="primary--text title font-weight-black"
                            >Número de Títulos selecionados:
                            {{ selecionados.length }}
                        </v-subheader>
                        <v-subheader
                            >Os seguintes títulos terão {{type}}
                            Solicitada:</v-subheader
                        >
                        <v-list-item
                            v-for="(item, i) in selecionados"
                            :key="i"
                            shaped
                        >
                            <v-row>
                                <v-col>
                                    <v-card-text class="bold">
                                        <v-icon class="ma-1"
                                            >mdi-information</v-icon
                                        >Título:
                                        {{ item.ds_nosso_numero }}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-text
                    v-if="resultadoCheck"
                    class="mx-auto"
                    min-height="200px"
                    style="border-radius: 0px"
                >
                    <v-list class="mx-auto">
                        <v-subheader
                            >Confira abaixo se todos os títulos tiveram sua
                            {{type}} confirmada:</v-subheader
                        >
                        <v-list-item-group>
                            <v-list
                                class="mx-auto"
                                v-for="item in avisoRetorno"
                                :key="item.id"
                                v-model="item.active"
                                :prepend-icon="item.action"
                                no-action
                                shaped
                            >
                                <v-list-item shaped>
                                    <v-row>
                                        <v-col
                                            md="2"
                                            lg="4"
                                            class="ma-auto"
                                        >
                                            <span
                                                v-if="
                                                    item.retorno.codigo ===
                                                        200
                                                "
                                            >
                                                <v-btn
                                                    small
                                                    block
                                                    class="success ma-auto"
                                                    ><v-icon class=""
                                                        >mdi-check-circle</v-icon
                                                    >
                                                    Realizado
                                                </v-btn>
                                            </span>
                                            <span
                                                v-if="
                                                    item.retorno.codigo ===
                                                        400
                                                "
                                            >
                                                <v-btn
                                                    small
                                                    block
                                                    class="error ma-auto"
                                                    ><v-icon class=""
                                                        >mdi-close-circle</v-icon
                                                    >
                                                    Não Realizado
                                                </v-btn>
                                            </span>
                                        </v-col>
                                        <v-col md="10" lg="8">
                                            <v-card-text
                                                class="font-weight-black"
                                            >
                                                Número do Título:
                                                {{ item.titulo }}
                                            </v-card-text>
                                            <v-card-text>
                                                ID do Título:
                                                {{ item.id }}
                                            </v-card-text>
                                            <v-card-text>
                                                Resultado da Solicitação do
                                                Título:
                                                {{ item.retorno.mensagem }}
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                            </v-list>
                        </v-list-item-group>
                        <v-card-text
                            class="ma-auto text-center font-weight-black"
                            >Iniciando download dos
                            resultados...</v-card-text
                        >
                        <v-card-actions class="justify-center">
                            <v-btn
                                class="grey white--text d-flex justify-center"
                                medium
                                v-model="botaoRelatorioXls"
                            >
                                <download-excel
                                    :data="avisoRetorno"
                                    :fields="json_fields"
                                    name="Resultado.xls"
                                    worksheet="resultados"
                                    class="ma-auto"
                                    type="xls"
                                >
                                    <v-icon class="ma-3"
                                        >mdi-microsoft-excel</v-icon
                                    >Baixar XLS Resultados
                                </download-excel>
                            </v-btn>
                        </v-card-actions>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions
                    v-if="titulosEnviadosCheck"
                    class="justify-end"
                >
                    <v-btn
                        class="ma-2"
                        color="success"
                        @click="confirmarEnvio"
                        :loading="loadingFiltros"
                    >
                        <v-icon> mdi-chevron-right</v-icon>
                        Enviar</v-btn
                    ><v-btn class="ma-2" @click="closeDialog"
                        >Cancelar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="confirmAgrupador"
            v-model="confirmAgrupador"
            max-width="800px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-toolbar-title v-if="titulosEnviadosCheck">
                        Solicitação de Alteração de Agrupador
                    </v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text
                    min-height="200px"
                    style="border-radius: 0"
                    v-if="titulosEnviadosCheck"
                >
                    <v-list shaped>
                        <v-subheader
                            class="primary--text title font-weight-black"
                            >Número de Títulos selecionados:
                            {{ selecionados.length }}
                        </v-subheader>
                        <v-subheader>
                            Os Títulos abaixo terão a mudança de Agrupador
                            solicitada. Utilize o campo abaixo para definir
                            o novo agrupador a partir da listagem de
                            Agrupadores disponíveis. Importante: ao
                            selecionar "Nenhum Agrupador", todos os Títulos
                            selecionados terão seu agrupador removido.
                        </v-subheader>
                        <v-row class="mx-0 mb-0 mt-3">
                            <v-col cols="4" xs="12" sm="12" md="6" lg="6">
                                <v-select
                                    outlined
                                    dense
                                    color="success"
                                    :disabled="loadingAgrupador"
                                    :loading="loadingAgrupador"
                                    :items="agrupadores"
                                    v-model="novoAgrupador"
                                    label="Novo Agrupador"
                                    required
                                    item-text="nm_agrupador"
                                    item-value="ds_agrupador"
                                    data-cy="novoAgrupador"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col
                                cols="4"
                                xs="6"
                                sm="6"
                                md="6"
                                lg="6"
                                class="font-weight-bold"
                            >
                                Título
                            </v-col>
                            <v-col
                                cols="4"
                                xs="6"
                                sm="6"
                                md="6"
                                lg="6"
                                class="font-weight-bold"
                            >
                                Agrupador atual
                            </v-col>
                        </v-row>
                        <v-list-item
                            v-for="(item, i) in selecionados"
                            :key="i"
                            shaped
                            class="px-0"
                        >
                            <v-row class="ma-0">
                                <v-col
                                    cols="4"
                                    xs="6"
                                    sm="6"
                                    md="6"
                                    lg="6"
                                    class="pa-0"
                                >
                                    <v-card-text class="bold py-3">
                                        <v-icon class="ma-1"
                                            >mdi-information</v-icon
                                        >Título:
                                        {{ item.ds_nosso_numero }}
                                    </v-card-text>
                                </v-col>
                                <v-col
                                    cols="4"
                                    xs="6"
                                    sm="6"
                                    md="6"
                                    lg="6"
                                    class="pa-0"
                                >
                                    <v-card-text class="bold py-3">
                                        {{
                                            item.ds_agrupador
                                                ? item.ds_agrupador
                                                : '--'
                                        }}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions
                    v-if="titulosEnviadosCheck"
                    class="justify-end"
                >
                    <v-btn
                        class="ma-2"
                        color="success"
                        @click="alteracaoAgrupadorMassa"
                        :loading="loadingFiltros"
                    >
                        <v-icon> mdi-chevron-right</v-icon>
                        Solicitar</v-btn
                    ><v-btn
                        class="ma-2"
                        @click="closeDialog"
                        :loading="loadingFiltros"
                        >Cancelar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import downloadExcel from 'vue-json-excel';
export default {
    props: {
        selecionados: {
            type: Array,
            required: true,
        },
        avisoRetorno: {
            type: Array,
            required: false,
        },
        resultadoCheck: {
            type: Boolean,
            required: false,
        },
        loadingFiltros: {
            type: Boolean,
            required: false,
        },
        titulosEnviadosCheck: {
            type: Boolean,
            required: false,
        },
        type: {
            type: String,
            required: false,
        },
        agrupadores: {
            type: Array,
            required: false,
        },
        
    },
    components: {
        downloadExcel,
    },
    data() {
        return {
            novoAgrupador: null,
            temCerteza: false,
            botaoRelatorioXls: null,
            confirmAgrupador: false,
            mostrarBotaoFlutuante: false,
            loadingAgrupador: false,
            json_fields: {
                ID: 'id',
                Código: 'retorno.codigo',
                Mensagem: 'retorno.mensagem'
            }
        };
    },
    methods: {
        alteracaoAgrupadorMassa() {
            let data = this.selecionados.map(e => e.ds_nosso_numero);
            this.$emit("enviar", data, this.novoAgrupador);
        },
        onIntersect(entries) {
            const isIntersecting = entries[0].isIntersecting;
            this.mostrarBotaoFlutuante = !isIntersecting;
        },
        dialogTemCerteza() {
            if(this.type === 'Agrupador'){
                this.confirmAgrupador = true;
            }else{
                this.temCerteza = true;
            }
        },
        fecharDialog() {
            this.temCerteza = false;
            this.confirmAgrupador = false;
        },       
        closeDialog() {
            this.temCerteza = false;
            window.location.reload();
        },
        confirmarEnvio() {
            let data = this.selecionados.map(e => e.ds_nosso_numero);
            this.$emit("enviar", data);
        },
    },
};

</script>