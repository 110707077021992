import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mostrarBotaoFlutuante && _vm.selecionados.length !== 0
        ? _c(
            VTooltip,
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          VBtn,
                          _vm._g(
                            {
                              attrs: {
                                fab: "",
                                dark: "",
                                color: "green",
                                fixed: "",
                                right: "",
                                bottom: ""
                              },
                              on: { click: _vm.dialogTemCerteza }
                            },
                            on
                          ),
                          [
                            _c(VIcon, { attrs: { dark: "" } }, [
                              _vm._v("check")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                350095688
              )
            },
            [
              _c("span", [
                _vm._v("Solicitar " + _vm._s(_vm.type) + " para Selecionados")
              ])
            ]
          )
        : _vm._e(),
      _c(
        VCard,
        { staticClass: "mt-3" },
        [
          _c(
            VRow,
            { staticClass: "ma-0" },
            [
              _c(VSpacer),
              _c(
                VCol,
                {
                  staticClass: "d-flex justify-end pr-9",
                  attrs: { sm: "6", md: "3", lg: "4" }
                },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: {
                        top: "",
                        disabled: _vm.selecionados.length !== 0
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g({}, on),
                                [
                                  _c(
                                    VBtn,
                                    {
                                      directives: [
                                        {
                                          def: Intersect,
                                          name: "intersect",
                                          rawName: "v-intersect",
                                          value: _vm.onIntersect,
                                          expression: "onIntersect"
                                        }
                                      ],
                                      staticClass: "d-flex justify-center",
                                      attrs: {
                                        color: "green white--text",
                                        disabled: !_vm.selecionados.length
                                      },
                                      on: { click: _vm.dialogTemCerteza }
                                    },
                                    [
                                      _vm._v(
                                        " Solicitar " +
                                          _vm._s(_vm.type) +
                                          " para Selecionados "
                                      ),
                                      _c(VIcon, { staticClass: "ml-2" }, [
                                        _vm._v("mdi-chevron-right")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Selecione um ou mais títulos para liberar o comando"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.temCerteza
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "800px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.temCerteza,
                callback: function($$v) {
                  _vm.temCerteza = $$v
                },
                expression: "temCerteza"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VToolbar,
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _vm.titulosEnviadosCheck
                        ? _c(VToolbarTitle, [_vm._v(" Tem certeza? ")])
                        : _vm._e(),
                      _vm.resultadoCheck
                        ? _c(VToolbarTitle, [_vm._v(" Envio Finalizado! ")])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(VDivider),
                  _vm.titulosEnviadosCheck
                    ? _c(
                        VCardText,
                        {
                          staticStyle: { "border-radius": "0" },
                          attrs: { "min-height": "200px" }
                        },
                        [
                          _c(
                            VList,
                            { attrs: { shaped: "" } },
                            [
                              _c(
                                VSubheader,
                                {
                                  staticClass:
                                    "primary--text title font-weight-black"
                                },
                                [
                                  _vm._v(
                                    "Número de Títulos selecionados: " +
                                      _vm._s(_vm.selecionados.length) +
                                      " "
                                  )
                                ]
                              ),
                              _c(VSubheader, [
                                _vm._v(
                                  "Os seguintes títulos terão " +
                                    _vm._s(_vm.type) +
                                    " Solicitada:"
                                )
                              ]),
                              _vm._l(_vm.selecionados, function(item, i) {
                                return _c(
                                  VListItem,
                                  { key: i, attrs: { shaped: "" } },
                                  [
                                    _c(
                                      VRow,
                                      [
                                        _c(
                                          VCol,
                                          [
                                            _c(
                                              VCardText,
                                              { staticClass: "bold" },
                                              [
                                                _c(
                                                  VIcon,
                                                  { staticClass: "ma-1" },
                                                  [_vm._v("mdi-information")]
                                                ),
                                                _vm._v(
                                                  "Título: " +
                                                    _vm._s(
                                                      item.ds_nosso_numero
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resultadoCheck
                    ? _c(
                        VCardText,
                        {
                          staticClass: "mx-auto",
                          staticStyle: { "border-radius": "0px" },
                          attrs: { "min-height": "200px" }
                        },
                        [
                          _c(
                            VList,
                            { staticClass: "mx-auto" },
                            [
                              _c(VSubheader, [
                                _vm._v(
                                  "Confira abaixo se todos os títulos tiveram sua " +
                                    _vm._s(_vm.type) +
                                    " confirmada:"
                                )
                              ]),
                              _c(
                                VListItemGroup,
                                _vm._l(_vm.avisoRetorno, function(item) {
                                  return _c(
                                    VList,
                                    {
                                      key: item.id,
                                      staticClass: "mx-auto",
                                      attrs: {
                                        "prepend-icon": item.action,
                                        "no-action": "",
                                        shaped: ""
                                      },
                                      model: {
                                        value: item.active,
                                        callback: function($$v) {
                                          _vm.$set(item, "active", $$v)
                                        },
                                        expression: "item.active"
                                      }
                                    },
                                    [
                                      _c(
                                        VListItem,
                                        { attrs: { shaped: "" } },
                                        [
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "ma-auto",
                                                  attrs: { md: "2", lg: "4" }
                                                },
                                                [
                                                  item.retorno.codigo === 200
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "success ma-auto",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c(VIcon, {}, [
                                                                _vm._v(
                                                                  "mdi-check-circle"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Realizado "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.retorno.codigo === 400
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "error ma-auto",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c(VIcon, {}, [
                                                                _vm._v(
                                                                  "mdi-close-circle"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Não Realizado "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                VCol,
                                                {
                                                  attrs: { md: "10", lg: "8" }
                                                },
                                                [
                                                  _c(
                                                    VCardText,
                                                    {
                                                      staticClass:
                                                        "font-weight-black"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Número do Título: " +
                                                          _vm._s(item.titulo) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(VCardText, [
                                                    _vm._v(
                                                      " ID do Título: " +
                                                        _vm._s(item.id) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c(VCardText, [
                                                    _vm._v(
                                                      " Resultado da Solicitação do Título: " +
                                                        _vm._s(
                                                          item.retorno.mensagem
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                VCardText,
                                {
                                  staticClass:
                                    "ma-auto text-center font-weight-black"
                                },
                                [_vm._v("Iniciando download dos resultados...")]
                              ),
                              _c(
                                VCardActions,
                                { staticClass: "justify-center" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass:
                                        "grey white--text d-flex justify-center",
                                      attrs: { medium: "" },
                                      model: {
                                        value: _vm.botaoRelatorioXls,
                                        callback: function($$v) {
                                          _vm.botaoRelatorioXls = $$v
                                        },
                                        expression: "botaoRelatorioXls"
                                      }
                                    },
                                    [
                                      _c(
                                        "download-excel",
                                        {
                                          staticClass: "ma-auto",
                                          attrs: {
                                            data: _vm.avisoRetorno,
                                            fields: _vm.json_fields,
                                            name: "Resultado.xls",
                                            worksheet: "resultados",
                                            type: "xls"
                                          }
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            { staticClass: "ma-3" },
                                            [_vm._v("mdi-microsoft-excel")]
                                          ),
                                          _vm._v("Baixar XLS Resultados ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(VDivider),
                  _vm.titulosEnviadosCheck
                    ? _c(
                        VCardActions,
                        { staticClass: "justify-end" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "success",
                                loading: _vm.loadingFiltros
                              },
                              on: { click: _vm.confirmarEnvio }
                            },
                            [
                              _c(VIcon, [_vm._v(" mdi-chevron-right")]),
                              _vm._v(" Enviar")
                            ],
                            1
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-2",
                              on: { click: _vm.closeDialog }
                            },
                            [_vm._v("Cancelar")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.confirmAgrupador
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "800px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.confirmAgrupador,
                callback: function($$v) {
                  _vm.confirmAgrupador = $$v
                },
                expression: "confirmAgrupador"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VToolbar,
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _vm.titulosEnviadosCheck
                        ? _c(VToolbarTitle, [
                            _vm._v(" Solicitação de Alteração de Agrupador ")
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(VDivider),
                  _vm.titulosEnviadosCheck
                    ? _c(
                        VCardText,
                        {
                          staticStyle: { "border-radius": "0" },
                          attrs: { "min-height": "200px" }
                        },
                        [
                          _c(
                            VList,
                            { attrs: { shaped: "" } },
                            [
                              _c(
                                VSubheader,
                                {
                                  staticClass:
                                    "primary--text title font-weight-black"
                                },
                                [
                                  _vm._v(
                                    "Número de Títulos selecionados: " +
                                      _vm._s(_vm.selecionados.length) +
                                      " "
                                  )
                                ]
                              ),
                              _c(VSubheader, [
                                _vm._v(
                                  ' Os Títulos abaixo terão a mudança de Agrupador solicitada. Utilize o campo abaixo para definir o novo agrupador a partir da listagem de Agrupadores disponíveis. Importante: ao selecionar "Nenhum Agrupador", todos os Títulos selecionados terão seu agrupador removido. '
                                )
                              ]),
                              _c(
                                VRow,
                                { staticClass: "mx-0 mb-0 mt-3" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      attrs: {
                                        cols: "4",
                                        xs: "12",
                                        sm: "12",
                                        md: "6",
                                        lg: "6"
                                      }
                                    },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          color: "success",
                                          disabled: _vm.loadingAgrupador,
                                          loading: _vm.loadingAgrupador,
                                          items: _vm.agrupadores,
                                          label: "Novo Agrupador",
                                          required: "",
                                          "item-text": "nm_agrupador",
                                          "item-value": "ds_agrupador",
                                          "data-cy": "novoAgrupador",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.novoAgrupador,
                                          callback: function($$v) {
                                            _vm.novoAgrupador = $$v
                                          },
                                          expression: "novoAgrupador"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: {
                                        cols: "4",
                                        xs: "6",
                                        sm: "6",
                                        md: "6",
                                        lg: "6"
                                      }
                                    },
                                    [_vm._v(" Título ")]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: {
                                        cols: "4",
                                        xs: "6",
                                        sm: "6",
                                        md: "6",
                                        lg: "6"
                                      }
                                    },
                                    [_vm._v(" Agrupador atual ")]
                                  )
                                ],
                                1
                              ),
                              _vm._l(_vm.selecionados, function(item, i) {
                                return _c(
                                  VListItem,
                                  {
                                    key: i,
                                    staticClass: "px-0",
                                    attrs: { shaped: "" }
                                  },
                                  [
                                    _c(
                                      VRow,
                                      { staticClass: "ma-0" },
                                      [
                                        _c(
                                          VCol,
                                          {
                                            staticClass: "pa-0",
                                            attrs: {
                                              cols: "4",
                                              xs: "6",
                                              sm: "6",
                                              md: "6",
                                              lg: "6"
                                            }
                                          },
                                          [
                                            _c(
                                              VCardText,
                                              { staticClass: "bold py-3" },
                                              [
                                                _c(
                                                  VIcon,
                                                  { staticClass: "ma-1" },
                                                  [_vm._v("mdi-information")]
                                                ),
                                                _vm._v(
                                                  "Título: " +
                                                    _vm._s(
                                                      item.ds_nosso_numero
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          VCol,
                                          {
                                            staticClass: "pa-0",
                                            attrs: {
                                              cols: "4",
                                              xs: "6",
                                              sm: "6",
                                              md: "6",
                                              lg: "6"
                                            }
                                          },
                                          [
                                            _c(
                                              VCardText,
                                              { staticClass: "bold py-3" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.ds_agrupador
                                                        ? item.ds_agrupador
                                                        : "--"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(VDivider),
                  _vm.titulosEnviadosCheck
                    ? _c(
                        VCardActions,
                        { staticClass: "justify-end" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "success",
                                loading: _vm.loadingFiltros
                              },
                              on: { click: _vm.alteracaoAgrupadorMassa }
                            },
                            [
                              _c(VIcon, [_vm._v(" mdi-chevron-right")]),
                              _vm._v(" Solicitar")
                            ],
                            1
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-2",
                              attrs: { loading: _vm.loadingFiltros },
                              on: { click: _vm.closeDialog }
                            },
                            [_vm._v("Cancelar")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }